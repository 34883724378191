/* Fields */

td > .field-container span, .sonata-ba-field-standard-natural > .field-container span, td > .field-container > .field-widget:not(.inline),
.sonata-ba-field-standard-natural > .field-container > .field-widget:not(.inline) {
    flex: 1 1 80%;
    margin-right: 5px;
}

.sonata-ba-field-standard-natural .field-actions {
    display: unset;
    margin-top: 0;
}

td > .field-container,
.sonata-ba-field-standard-natural.sonata-ba-field > .field-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

td > .field-container > .field-actions,
.sonata-ba-field-standard-natural > .field-container > .field-actions {
    flex: 1 1 20%;
    margin-top: 0;
}

.field-container > .field-actions > .btn-group .btn,
.field-container > .field-actions > .btn {
    line-height: 20px;
}

.scrollable-field {
    overflow-x: auto;
}

.scrollable-field th, .scrollable-field td {
    min-width: 200px;
}

.scrollable-field th:first-of-type,
.scrollable-field td:first-of-type {
    min-width: 100px;
}

.table .sonata-ba-tbody td.error.sonata-ba-field-error textarea,
.table .sonata-ba-tbody td.error.sonata-ba-field-error .select2-choice,
.table .sonata-ba-tbody td.error.sonata-ba-field-error .form-control {
    border-color: #dd4b39;
}

.table .sonata-ba-tbody td.error.sonata-ba-field-error .sonata-ba-field-error-messages {
    color: #dd4b39;
}

.sonata-ba-field textarea {
    height: 34px;
}

.login-page .login-logo img {
    width: 100%!important;
}

.logo img {
    max-width: 100%;
}

.radio label.required:after {
    display: none;
}

.sonata-ba-field .vich-image a img {
    max-height: 100px;
    margin-right: 10px;
}

.select2-container.question-admin-problem-type {
    width: 100%
}

/* Theme */
.skin-black .wrapper,
.skin-black .main-sidebar,
.skin-black .left-side,
.sidebar {
    background: #003466;
}

.skin-black .sidebar-form input[type="text"], .skin-black .sidebar-form .btn {
    background: white;
}
.skin-black .sidebar-menu>li:hover>a, .skin-black .sidebar-menu>li.active>a {
    background: #73AA4F;
}
.skin-black .sidebar-menu>li>.treeview-menu {
    background: #73AA4F;
}

.skin-black .treeview-menu>li>a {
    color: #ececec;
}

.skin-black .sidebar-form {
    border-color: #0085ca;
}

.skin-black .sidebar a {
    color: #ececec!important;
}

.skin-black .sidebar a:hover {
    color: #fff!important;
}

.main-sidebar .slimScrollBar {
    background: none!important;
}

.main-sidebar .sidebar > p.text-center.small {
    display: none;
}

.content-wrapper, .right-side {
    background: #ececec;
}

.skin-black .main-header .navbar .nav>li>a,
.skin-black .main-header .navbar>.sidebar-toggle {
    color: #0085ca;
}

.box.box-primary,
.nav-tabs-custom>.nav-tabs>li.active {
    border-top-color: #73AA4F;
}

.skin-black .main-header .navbar .nav>li>a:hover,
.skin-black .main-header .navbar .nav>li>a:active,
.skin-black .main-header .navbar .nav>li>a:focus,
a:hover, a:active, a:focus,
.skin-black .main-header .navbar .sidebar-toggle:hover {
    color: #73AA4F;
}

.sidebar-menu li>a {
    svg {
        margin-right: 5px;
    }
}

.ui-autocomplete {
    border: 1px solid #337ab7;
    border-top: none;
    border-radius: unset;
    margin: 4px;
}

.ui-menu .ui-menu-item {
    font-size: 13px!important;

    a {
        font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
        padding: 3px 7px 4px;
    }
}

.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
    background: #337ab7;
    color: #fff;
    border-radius: unset;
}

.date-flex {
    display: flex;
    justify-content: space-around;
}

.days-remaining {
    float: right;
    font-size: 18px;
}

.d-flex {
    display: flex;
}

.MuiInputBase-root, .MuiMenuItem-root, .MuiTypography-body1 {
    //background: #fff!important;
    //font-size: 14px!important;
    font-size: 12px!important;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
}

.MuiInputBase-root {
    //width: 25rem;
}

.proceedings {
    margin: 1em 0;
}

.card-container {
    .card-body {

        .form-group {
            margin: 0;
        }
    }
    margin-bottom: 1em;


    &:hover, &:focus {
        background: #f5f5f5!important;

        select, textarea, .speaker-select div {
            background: #f5f5f5!important;
        }

        .is-recess-container {
            display: block;
        }
    }

    .is-recess-container {
        display: none;
    }


    .form-container {
        padding: 0 1rem;
    }

    .MuiFormGroup-root {
        flex-direction: row-reverse;
    }

    //border-bottom: 1px solid #e4e2e2;
    //padding: 1em 0;
    font-family: sans-serif;

    &:hover .card-title a, &:hover .card-title button {
        visibility: visible;
    }

    .card-title {
        justify-content: space-between;
        align-items: center;

        a, button {
            visibility: hidden;
        }
    }

    .text {
        letter-spacing: .2px;
        font-size: 15px;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        color: #6f747b;
        line-height: 28px;
        outline: none;
        border: none;
        resize: none;
        overflow: hidden;
        height: auto;
        padding: 5px 10px;

        &:focus {
            //font-size: 16px;
            color: black !important;
            transition: color 1s, font-size 1s;
            background: #f5f5f5;
        }
    }


    textarea {
        height: 4em;
    }

    .card-edit {
        margin-right: .5em;
    }

    .card-btn {
        float: right;
        text-align: right;

        a {
            margin-right: .5em;
        }
    }

    .col-md-2 {
        @media (min-width: 992px) {
            width: 14.666667%;
            padding-right: 4px;
        }
    }

    .speaker {
        font-family: Open Sans, sans-serif;
        color: #6f747b;
        font-size: 10px;
        font-style: italic;
        //padding-top:
        line-height: 28px;
    }

    .hover-hand:hover {
        cursor: pointer;

        &.speaker {
            //border-bottom: 1px solid #3c8dbc!important;
        }
    }

    .minimal {
        &:hover {
            background: #f5f5f5;

            &:after {
                content: "";
                position: absolute;
                right: 45px;
                top: 14px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #333;
            }
        }
    }


    .speakerId.form-control {
        border: none;
        //border-bottom: 1px solid #3c8dbc!important;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        font-family: Open Sans,sans-serif;
        color: #6f747b;
        font-size: 10px;

        &:hover {
            background: #f5f5f5;
        }

        .caret-off::before {
            display: none;
        }
        .caret-off::after {
            display: none;
        }
    }

}

.MuiInput-underline:before {
    border-bottom: 1px solid #d2d6de!important;
}
.MuiInput-underline:before {
    transition: none!important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #3c8dbc!important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #3c8dbc!important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(60, 141, 188, 0.04)!important;
}

.MuiSvgIcon-root {
    fill: #3c8dbc;
    //fill: #e5e5e5!important;
    width: 1em!important;
    height: 1.5em!important;
}

.modal-backdrop {
    background: transparent;
}

.actions {
    width: 8em;
     a:first-child {
        margin-right: .5em;
    }
    //button {
    //    margin-left: .5em;
    //}
}

 .error-message {
    color: #dd4b39;
}

.success-message {
    color: #155724;
}

.table .error .form-control {
    border-color: #dd4b39;
}

.btn-primary {
    background-color: #003466;
    border-color: #003466;
}

.card-on-new {
    //display: inline-block!important;
}

.row.error .card h5 {
    color: #dd4b39;
}

.MuiFormControl-root {
    width: 80%!important;
    //border: none!important;
    outline: none!important;
    border: 1px solid #d2d6de!important;
    margin: 0!important;

    div {
        outline: none!important;
        border: none!important;
    }
}